<template lang="html">

  <section class="convenios">
    <hero-global img="/img/hero/informacion-economica.svg" title="Convenios" subtitle="Convenios y Acuerdos Institucionales" />
   
    <section id="convenios" class="container">
      <section class="text-center position-relative ">
        <div class="box-content container" v-if="Object.keys(store.agreements)">
          <a class="ver-mas" @click="toggleShowSubMenuResponsive" title="Ir a Ver más convenios" alt="Ir a Ver más convenios">Ver más convenios<img src="/img/flecha-desplegable-azul.png" v-bind:class="{ arriba: mostrar }"></a>

          <ul class="menu-list container" v-bind:class="{ active: mostrar }">
            <li v-for="(year, key) in Object.keys(store.agreements).reverse()" :key="key">
              <button :class="[yearSelected == year ? 'active-button' : '']" @click="yearSelected = year">Convenios {{year}}</button>
            </li>
          </ul>
          <div class="content container">
            <div class="d-flex">
              <b class="title-decoration-bottom font-family-frank-ruhl text-left">Convenios {{yearSelected}}</b>
            </div>
            <table class="table-style" v-if="dataAgreement != 0 && dataAgreement">
              <tr class="tr-head">
                <td>Denominación subvención</td>
                <td>Objeto</td>
                <td>Cuantía económica</td>
                <td>Personas destinatarias</td>
                <td>Período de ejecución</td>
              </tr>

              <tr v-for="(i, key) in dataAgreement" :key="key">
                <td>
                  {{i.name}}
                </td>
                <td>{{i.object}}</td>
                <td>{{i.quantity}}</td>
                <td>{{i.recipients}}</td>
                <td>{{i.execute_period}}</td>
              </tr>

            </table>
            <div class="convenios-responsive" v-if="dataAgreement != 0 && dataAgreement">
              <ul  v-for="(i, key) in dataAgreement" :key="key">
                <li><span>Denominación subvención: </span>{{i.name}}</li>
                <li><span>Objeto: </span>{{i.object}}</li>
                <li><span>Cuantía económica: </span>{{i.quantity}}</li>
                <li><span>Personas destinatarias: </span>{{i.recipients}}</li>
                <li><span>Período de ejecución: </span>{{i.execute_period}}</li>
                <hr>

              </ul>
            </div>
          </div>
        </div>
      </section>
    </section>
   
    <carrousel-2 class="container m-bottom-40-px" :dataContent="$tm('translations.carrouselData.cermi')" />
    <other-links-interest class="links-interest-box container" />

  </section>

</template>

<script lang="js">
  import HeroGlobal from "@/components/Global/HeroGlobal";
  import OtherLinksInterest from "@/components/Layout/OtherLinksInterest";
  import Carrousel2 from "@/components/Global/Carrousel2";
  import { contentStore } from '@/stores/contents'
  export default {
    name: 'convenios',
    props: [],
    components: {
      OtherLinksInterest,
      HeroGlobal,
      Carrousel2
     
    },
    setup() {
      const store = contentStore();
      store.loadAgreements()

      return {
        store
      }
    },
    mounted() {
     
      if (this.$route.hash) {
        let element = this.$route.hash.replace('#', '')
        const offsetTop = document.getElementById(element).offsetTop - 150;
        setTimeout(function () {
          scroll({
            behavior: "smooth",
            top: offsetTop,
          });
        }, 200);
      }
    },
    data() {

      return {
        mostrar:false,

        yearSelected: new Date().getFullYear(),
      }
    },
    methods: {
      toggleShowSubMenuResponsive: function (event) {
           if(this.mostrar==true){
            this.mostrar=false;
           }else{
            this.mostrar=true;
           }

        },
    },
    computed: {
      
      dataAgreement() {
        if (this.store.agreements[this.yearSelected]) {
          return this.store.agreements[this.yearSelected].items
        }
        return null
      },
     
    },
    watch: {
      "$route"() {
        if (this.$route.hash) {
          let element = this.$route.hash.replace('#', '')
          const offsetTop = document.getElementById(element).offsetTop - 150;
          setTimeout(function () {
            scroll({
              behavior: "smooth",
              top: offsetTop,
            });
          }, 200);
        }
      }

    }

  }


</script>

<style scoped lang="scss">
  @import "@/styles/general.scss";

  .convenios {

    #colaboraciones-economicas .subtitle,
    #cuentas-presupuestos .subtitle {
      max-width: 68%;
    }

    .logos-box {
      max-width: 950px;
      padding: 30px 0;

      a {
        margin: 0;
        display: inline-block;
        text-align: center;
        padding: 0 20px;
        width: 100%;

        img {
          width: 100%;
          object-fit: contain;
          object-position: center center;
        }
      }
    }


  }
</style>